"use client";
import React, { useState, useEffect, useCallback } from "react";
import cx from "classnames";
import Image from "next/image";
import isString from "lodash/isString";
import helper from "@utils/helper";

import { isEmpty } from "lodash";
const View = ({
  wrapperProps = {},
  aspectRatio,
  speed = -1,
  animated = true,
  children,
  alt = "ehphuong-image",
  className,
  hasClose = false,
  objectFit = "cover",
  layout = "fill",
  scale = "1",
  zoom = false,
  ...props
}: any) => {
  const [src, setSrc] = useState(props.src);

  let wrapperAnimated = {},
    wrapImgAnimated = {};
  if (animated) {
    wrapperAnimated =
      speed === 0
        ? {}
        : {
            "data-scroll": true,
            "data-scroll-repeat": true,
          };
    wrapImgAnimated = {
      "data-scroll": true,
      "data-scroll-speed": speed,
    };
  }

  useEffect(() => {
    if (props && !props.src && isString(props.image)) {
      setSrc(props.image);
    }
  }, [props]);

  useEffect(() => {
    if (!helper.isExternal(props.src) && src !== props.src) {
      setSrc(props.src);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.src]);

  const onError = useCallback(() => {
    //if the mobile image's src doesn't already exist
    setSrc(props.src);
  }, [props.src]);
  if (isEmpty(src)) return null;
  return (
    <div
      {...wrapperAnimated}
      {...wrapperProps}
      className={cx(
        "relative overflow-hidden w-full h-full pv-img-wrapper rounded-sm",
        wrapperProps.className,
        { nonAnimated: !animated }
      )}
    >
      <div
        {...wrapImgAnimated}
        style={{
          ...(scale ? { transform: `scale(${scale})` } : {}),
        }}
        className={cx("pv-img", wrapperProps.className, {
          "swiper-zoom-container": zoom,
        })}
      >
        <Image
          className={cx("w-full h-full", className)}
          style={{
            aspectRatio,
            objectFit,
            ...(scale ? { transform: `scale(${scale})` } : {}),
          }}
          alt={alt}
          preload="true"
          fill={layout === "fill"}
          // loader={customLoader}
          onError={onError}
          {...props}
          src={src}
        />
      </div>
    </div>
  );
};
export default View;
