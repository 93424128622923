import Image from "@components/common/Image";
import styles from "./image.module.scss";
const View = (props: any) => {
  return (
    <Image
      wrapperProps={{ className: styles.wrapper }}
      speed={-0.5}
      alt={props.alt}
      src={props.src}
    />
  );
};
export default View;
