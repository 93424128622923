import React from "react";
import Link from "@components/common/Link";
import gstyles from "@gstyles/index";
import styled from "styled-components";
const View = ({ children, ...props }: any) => {
  return (
    <CustomLink target="_blank" {...props}>
      {children}
    </CustomLink>
  );
};

export default View;

const CustomLink = styled(Link)`
  a {
    color: ${gstyles.colors.link};
  }
`;
