import { find, map, isFunction, isEqual, isObject } from "lodash";
const normalizeArgs = (fn: any) => {
  if (fn && fn.matcher) return fn;

  return {
    matcher: () => true,
    render: fn,
  };
};

const renderPropsComposer = (...fns: any) => {
  const ListFns = map(fns, normalizeArgs);
  return (props: any) => {
    const renderPropsObj = find(ListFns, (fn) => {
      if (isFunction(fn.matcher)) {
        return fn.matcher(props);
      }
      return false;
    });
    if (renderPropsObj) {
      return renderPropsObj.render(props);
    }
    const renderPropsDefault = find(ListFns, (fn) =>
      isEqual(fn.matcher, "default")
    );
    if (renderPropsDefault) {
      return renderPropsDefault.render(props);
    }
    // default
    return null;
  };
};
export const match = (...fns: any) => {
  const val = find(fns, (fn) => {
    return fn.isTrue;
  });
  if (val && val.value) {
    return val.value;
  }
  if (isObject(val)) return val;
  const valDefault = find(fns, (fn) => isEqual(fn.isTrue, "default"));
  if (valDefault) {
    return valDefault.value;
  }
  return null;
};
export default renderPropsComposer;
