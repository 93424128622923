import Image from "@components/common/Image";
import styles from "./image.module.scss";
import styled from "styled-components";
import cx from "classnames";
import { isEmpty, get } from "lodash";
import { match } from "@utils/renderPropsComposer";
const View = ({
  image,
  label,
  aspectRatio,
  imagePosition,
  animated,
  className,
  ...props
}: any) => {
  if (isEmpty(image)) return null;
  const width = get(image, "meta.width");
  const height = get(image, "meta.height");
  const aspect = aspectRatio || `${width} / ${height}`;
  let effect = {
    scale: 1.1,
    speed: -1,
  };
  if (!animated) {
    effect.scale = 1;
    effect.speed = 0;
  }
  const key = get(image, "meta.key", "");
  return (
    <DIV
      className={cx("strapi-comp", className, styles.section, {
        [`${styles.vertical} image_vertical`]: height > width,
      })}
      style={match({ isTrue: height > width, value: { maxWidth: width } })}
      aspectRatio={aspect}
    >
      <Image
        key={key}
        {...{ ...image, ...props, ...effect }}
        aspectRatio={aspect}
        wrapperProps={{ className: styles.wrapper }}
      />
      {!!label && <div className={styles.text}>{label}</div>}
    </DIV>
  );
};
const DIV: any = styled.div`
  aspect-ratio: ${(props: any) => props.aspectRatio};
  .pv-img-wrapper {
    aspect-ratio: ${(props: any) => props.aspectRatio};
  }
`;
export default View;
