import Image from "@components/common/Image";
import styles from "./textImage.module.scss";
import cx from "classnames";
const View = ({
  title,
  subtitle,
  subtitle2,
  image,
  imagePosition,
  className,
  ...props
}: any) => {
  return (
    <div
      className={cx(
        "strapi-comp",
        className,
        styles.section,
        styles[imagePosition]
      )}
    >
      <div className="flex flex-col flex-1 ">
        <div className={cx("pv-title", styles.title)}>{title}</div>
        <div className={cx("pv-content", styles.content)}>{subtitle}</div>
        <div className={cx("pv-content", styles.content)}>{subtitle2}</div>
      </div>
      <div className={cx("flex flex-1", styles.boxImg)}>
        <Image
          {...image}
          wrapperProps={{ className: styles.image }}
          scale={1.1}
        />
      </div>
    </div>
  );
};
export default View;
