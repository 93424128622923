import styles from "./html.module.scss";
import cx from "classnames";

const View = ({ content, className }: any) => {
  return (
    <div
      className={cx("strapi-comp", className, styles.section)}
      dangerouslySetInnerHTML={{ __html: content }}
    />
  );
};
export default View;
