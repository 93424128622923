import React from "react";
// import Image from "@components/Image";
import styled from "styled-components";

const Paragraph = styled.p`
  margin-bottom: 1rem;
  font-weight: 300;
`;

const View = (props: any) => {
  return <Paragraph className="paragraph" {...props} />;
};

export default View;
