import React from "react";
// import Image from "@components/Image";
import styled from "styled-components";
import cx from "classnames";

const BlockQuote = styled.blockquote`
  color: #666;
  margin: 0;
  font-size: 1.625rem;
  padding-left: 2rem;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 2rem;
  margin-bottom: 2rem;
  border-left: 0.25em #eee solid;
  p {
    font-family: var(--font-family-third) !important;
    margin: 8px 0;
  }
`;

const View = ({ className, ...props }: any) => {
  return <BlockQuote className={cx("blockQuote", className)} {...props} />;
};

export default View;
