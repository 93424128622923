import React from "react";
// import Image from "@components/Image";
import styled from "styled-components";
// import cx from "classnames";
const Ol = styled.ol`
  margin-bottom: 1rem;
  list-style: number;
  padding-left: 1.5rem;
`;

const View = ({ className, node, ...props }: any) => {
  return <Ol {...props} />;
};

export default View;
