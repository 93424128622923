import Markdown from "@components/common/Markdown";
import styles from "./paragraph.module.scss";
import cx from "classnames";
const Components = {
  p: ({ children, node, ...props }: any) => {
    return (
      <p className={cx("pv-content paragraph", styles.paragraph)} {...props}>
        {children}
      </p>
    );
  },
};
const View = ({ content, className }: any) => {
  return (
    <div className={cx("strapi-comp", className, styles.section)}>
      <Markdown components={Components} content={content} />
    </div>
  );
};
export default View;
