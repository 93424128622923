import Image from "@components/strapi/image";
import getter from "@services/StrapiService/getter";
import { get } from "lodash";

const View = (item: any) => {
  const props = { ...item, image: getter.image(get(item, "image")) };
  return <Image {...props} />;
};

View.strapiName = "ComponentCommonImage";

export default View;
