import TextImage from "@components/strapi/textImage";
import getter from "@services/StrapiService/getter";
import { get } from "lodash";
const View = ({ ...item }) => {
  const props = { ...item, image: getter.image(get(item, "image")) };
  return <TextImage {...props} />;
};

View.strapiName = "ComponentCommonTextImage";

export default View;
