import Paragraph from "@components/strapi/paragraph";
import HTML from "@components/strapi/html";
import renderPropsComposer from "@utils/renderPropsComposer";
const View = ({ type, ...item }: any) => {
  return renderPropsComposer(
    {
      matcher: ({ type }: any) => type === "html",
      render: () => {
        return <HTML {...item} />;
      },
    },
    {
      matcher: "default",
      render: () => {
        return <Paragraph {...item} />;
      },
    }
  )({ type });
};

View.strapiName = "ComponentCommonText";

export default View;
