import React from "react";
import * as comps from "./components";
import { compact, isFunction, get, castArray, find, map } from "lodash";
export const findComp = (item: any, index: number) => {
  const entries = comps;
  const Component: any = find(entries, (comp: any) => {
    return (
      get(comp, "strapiName") === get(item, "__component") ||
      get(comp, "strapiName") === get(item, "__typename")
    );
  });
  return isFunction(Component) ? Component(item) : null;
  // comps
};
export const renderComp = (data: any) => {
  return compact(map(castArray(data), (item, index) => findComp(item, index)));
};
export const renderHTML = (data: any) => {
  return compact(
    map(castArray(data), (item, index) =>
      findComp(
        { type: "html", ...{ ...item, key: `${item.__typename}_${index}` } },
        index
      )
    )
  );
};
