import React from "react";
// import Image from "@components/Image";
import styled from "styled-components";
import cx from "classnames";
const H2 = styled.h2`
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
`;

const View = ({ className, node, ...props }: any) => {
  return <H2 className={cx("pv-title", className)} {...props} />;
};

export default View;
