"use client";
import React from "react";

import Paragraph from "./components/paragraph";
import H2 from "./components/h2";
import Ol from "./components/ol";
import Ul from "./components/ul";
import Li from "./components/li";
import Link from "./components/link";
import CodeBlock from "./components/codeBlock";
import BlockQuote from "./components/blockQuote";
import Image from "./components/image";

import ReactMarkdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import remarkBreaks from "remark-breaks";
import remarkGfm from "remark-gfm";
import cx from "classnames";

const DEFAULT_COMPONENTS = {
  p: Paragraph,
  h2: H2,
  ol: Ol,
  ul: Ul,
  li: Li,
  a: Link,
  code: CodeBlock,
  blockquote: BlockQuote,
  img: (props: any) => {
    return <Image {...props} />;
  },
};

const View = ({
  content,
  components = {},
  className,
  ...props
}: {
  content: any;
  components?: any;
  className?: string;
  [key: string]: any;
}) => {
  const comps = { ...DEFAULT_COMPONENTS, ...components };
  return (
    <div className={cx("relative", className)}>
      <ReactMarkdown
        {...props}
        components={comps}
        remarkPlugins={[remarkBreaks, remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      >
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default View;
