import styled from "styled-components";

const View = ({ ...props }: any) => {
  return <Ul {...props} />;
};
export default View;
const Ul = styled.ul`
  list-style: outside;
  font-weight: 300;
  margin-bottom: 1rem;
  padding-left: 1.5rem;
`;
