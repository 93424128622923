"use client";
import React from "react";
// import Image from "@components/Image";
import { PrismLight as SyntaxHighlighter } from "react-syntax-highlighter";
import { useTheme } from "next-themes";
import cx from "classnames";
// @ts-ignore: Unreachable code error
import {
  oneLight,
  oneDark,
} from "react-syntax-highlighter/dist/cjs/styles/prism";

import tsx from "react-syntax-highlighter/dist/cjs/languages/prism/tsx";
import typescript from "react-syntax-highlighter/dist/cjs/languages/prism/typescript";
import scss from "react-syntax-highlighter/dist/cjs/languages/prism/scss";
import bash from "react-syntax-highlighter/dist/cjs/languages/prism/bash";
import markdown from "react-syntax-highlighter/dist/cjs/languages/prism/markdown";
import json from "react-syntax-highlighter/dist/cjs/languages/prism/json";

SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("tsx", tsx);
SyntaxHighlighter.registerLanguage("typescript", typescript);
SyntaxHighlighter.registerLanguage("scss", scss);
SyntaxHighlighter.registerLanguage("bash", bash);
SyntaxHighlighter.registerLanguage("markdown", markdown);
SyntaxHighlighter.registerLanguage("json", json);

import styled from "styled-components";
import { debounce, get } from "lodash";

const CustomSyntax = styled.div`
  position: relative;
  font-size: 1rem;
  .copy {
    position: absolute;
    top: 0.5em;
    right: 0.5em;
    padding: 8px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    border-radius: 6px;
    font-family: Quicksand;
    font-size: 0.875rem;
    font-weight: 500;
    color: white;
    background-color: rgba(0, 0, 0, 0.3);
  }
  &:hover {
    .copy {
      opacity: 1;
    }
  }
  margin-bottom: 1rem;
`;
const CustomCode = styled.code`
  display: inline;
  padding: 0 6px;
  border-radius: 0.3em;
  overflow: auto;
  &.dark {
    color: white;
  }
`;
const ButtonCopy = ({ value = "" }) => {
  const [copied, $copied] = React.useState(false);
  const handler = {
    copy: () => {
      if (!copied) {
        navigator.clipboard.writeText(value);
        $copied(true);
        debounce(() => {
          $copied(false);
        }, 2000)();
      }
    },
  };

  return (
    <div className="cursor-pointer copy" onClick={handler.copy}>
      {copied ? "Copied" : "Copy"}
    </div>
  );
};
const View = ({ node, inline, className, children, ...props }: any) => {
  const { theme } = useTheme();

  const match = get(/language-(\w+)/.exec(className || ""), "1", "");
  const handler: any = {
    embed_html: () => {
      return (
        <div
          dangerouslySetInnerHTML={{
            __html: String(children).replace(/\n$/, ""),
          }}
        />
      );
    },
  };

  if (handler[match]) return handler[match]();
  return (
    !!theme &&
    (!inline && match ? (
      <CustomSyntax className="code">
        <SyntaxHighlighter
          style={theme === "light" ? oneLight : oneDark}
          className="codeStyle"
          language={match}
          PreTag="div"
          // lineProps={applyHighlights}
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
        <ButtonCopy value={String(children)} />
      </CustomSyntax>
    ) : (
      <CustomCode className={cx(className, theme)} {...props}>
        {children}
      </CustomCode>
    ))
  );
};

export default View;
